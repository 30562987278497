import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Layout from "../components/Layout";

export const IndexPageTemplate = ({ title, cta, heroImage, pitches = [] }) => {
  return (
    <>
      <section className="o-hero page-home">
        <div className="o-hero__content o-content-container o-two-columns v--inverse">
          <header className="o-hero__header">
            <h1 className="o-hero__header__headline title is-1">{title}</h1>
          </header>
          <div>
            <a className="button is-link is-large" href={cta.link}>
              {cta.text}
            </a>
          </div>
          <PreviewCompatibleImage
            imageInfo={{ image: heroImage.src, alt: heroImage.title }}
            className="page-home-img"
          />
        </div>
      </section>

      <section className="page-home">
        <div className="o-content-container">
          {pitches.map((pitch, idx) => (
            <div className="feature" key={idx}>
              <div className="feature-description">
                <h2 className="title is-3">{pitch.heading}</h2>
                <p>{pitch.text}</p>
              </div>
              <div>
                {!!pitch.image.src && !!pitch.image.src.childImageSharp ? (
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: pitch.image.src,
                      alt: pitch.image.title
                    }}
                    className={
                      pitch.image.shadow ? "rounded-corner with-shadow" : ""
                    }
                  />
                ) : (
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: pitch.image.src.publicURL,
                      alt: pitch.image.title
                    }}
                    className={
                      pitch.image.shadow ? "rounded-corner with-shadow" : ""
                    }
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  cta: PropTypes.object,
  pitches: PropTypes.array
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        cta={frontmatter.cta}
        heroImage={frontmatter.heroImage}
        pitches={frontmatter.pitches}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        cta {
          link
          text
        }
        heroImage {
          title
          src {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        pitches {
          heading
          text
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            title
            shadow
          }
        }
      }
    }
  }
`;
